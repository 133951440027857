*:not(svg,path) {
  font-family: 'Roboto', sans-serif;
  color: #1D384B;
}

body {
  margin: 0;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.ant-form-item-explain-error{
  text-align: left;
}
.ant-layout .ant-layout-has-sider{
  background-color: #F5F8FD !important;
}
.ant-layout{
  background-color: #F5F8FD !important;
}
.ant-select-dropdown:has(.order){
  padding: 0 !important;
  border-radius: 0 0 10px 10px !important;
  box-shadow: none !important;
  & .ant-select-item-option-content{
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
  & .ant-select-item-option{
    border: 1px solid #F0F1F4;
    border-top: none;
  }
  & .ant-select-item-option:hover{
    background: #F5F8FD !important;
    color: #171B1E !important;
  }
}
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item, .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item, .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title{
  left: 0;
  display: flex;
  align-items: flex-end;
  padding: 0 ;
  padding-left: 16px;
}
.ant-menu-inline {
  & .logo{
  padding-left: 24px;
  }
  & .count{
    margin-right: 32px;
  }
}
.recharts-surface{
  overflow: inherit !important;
}
